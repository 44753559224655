import { useInputControl } from "@conform-to/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import type { ForwardedRef, JSX } from "react";
import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import { ProgressiveClientOnly } from "../ProgressiveClientOnly";
import type { CheckboxProps } from "./Checkbox";
import { Checkbox } from "./Checkbox";
import Input, { InputWithInfo } from "./Input";
import { Label, LabelOL, LabelOLnew } from "./Label";
import { Textarea } from "./TextArea";
import { requiredErrorLogic } from "./utils";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors;
  id?: string;
}) {
  let { t } = useTranslation();
  let errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map((e) => (
        <li
          key={e}
          className="text-[12px] text-foreground-destructive text-red-600"
        >
          {e ? t(e) : ""}
        </li>
      ))}
    </ul>
  );
}

export const Field = React.forwardRef(function Field(
  {
    labelProps,
    inputProps,
    errors,
    className,
    description,
    icon,
    iconRight,
    isResetAfterClickFileInput,
    prefix,
  }: {
    labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
    inputProps: React.InputHTMLAttributes<HTMLInputElement>;
    errors?: ListOfErrors;
    className?: string;
    description?: string;
    icon?: any;
    iconRight?: any;
    isResetAfterClickFileInput?: boolean;
    prefix?: string;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  let fallbackId = useId();
  let id = inputProps.id ?? fallbackId;
  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );

  return (
    <ProgressiveClientOnly className="animate-fade">
      <div className={className}>
        {inputProps.type !== "hidden" && (
          <Label
            htmlFor={id}
            {...labelProps}
            is_required={inputProps.required}
          />
        )}
        <Input
          {...inputProps}
          key={id}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          icon={icon}
          iconRight={iconRight}
          ref={ref}
          isRequiredError={isRequiredError}
          isResetAfterClickFileInput={isResetAfterClickFileInput}
          prefix={prefix}
        />
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
        {description ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <h2 className="text-gray-500 ">{description}</h2>
          </div>
        ) : null}
      </div>
    </ProgressiveClientOnly>
  );
});

export function FieldWithInfo({
  labelProps,
  inputProps,
  errors,
  className,
  description,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  description?: string;
}) {
  let fallbackId = useId();
  let id = inputProps.id ?? fallbackId;

  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );

  return (
    <ProgressiveClientOnly className="animate-fade">
      <div className={className}>
        {inputProps.type !== "hidden" && (
          <Label
            htmlFor={id}
            {...labelProps}
            is_required={inputProps.required}
          />
        )}
        <InputWithInfo
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          description={description}
          isRequiredError={isRequiredError}
          {...inputProps}
        />
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
      </div>
    </ProgressiveClientOnly>
  );
}

export function FieldOL({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  let fallbackId = useId();
  let id = inputProps.id ?? fallbackId;

  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );
  return (
    <ProgressiveClientOnly className="animate-fade">
      <div className={`${className + " grid grid-cols-3"} `}>
        <div className="col-start-1 col-span-1 justify-end justify-items-end text-end">
          {inputProps.type !== "hidden" && (
            <LabelOL
              htmlFor={id}
              {...labelProps}
              is_required={inputProps.required}
            />
          )}
        </div>
        <div className="col-start-2 col-span-1 justify-start justify-items-start text-start">
          <Input
            id={id}
            aria-invalid={errorId ? true : undefined}
            aria-describedby={errorId}
            isRequiredError={isRequiredError}
            {...inputProps}
          />
        </div>
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
      </div>
    </ProgressiveClientOnly>
  );
}

export function FieldOLnew({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  let fallbackId = useId();
  let id = inputProps.id ?? fallbackId;

  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );
  return (
    <ProgressiveClientOnly className="animate-fade">
      <div
        className={`${
          className + " flex flex-row justify-items-center items-center"
        } `}
      >
        {inputProps.type !== "hidden" && (
          <LabelOLnew
            htmlFor={id}
            {...labelProps}
            is_required={inputProps.required}
          />
        )}
        <Input
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          isRequiredError={isRequiredError}
          {...inputProps}
        />
      </div>
      {errorId ? (
        <div className="min-h-[32px] px-4 pb-3 pt-1">
          <ErrorList id={errorId} errors={tempErrorList} />
        </div>
      ) : null}
    </ProgressiveClientOnly>
  );
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  let fallbackId = useId();
  let id = textareaProps.id ?? textareaProps.name ?? fallbackId;

  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );
  return (
    <ProgressiveClientOnly className="animate-fade">
      <div className={className}>
        <Label
          htmlFor={id}
          {...labelProps}
          is_required={textareaProps.required}
        />
        <Textarea
          {...textareaProps}
          id={id}
          key={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          isRequiredError={isRequiredError}
        />
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
      </div>
    </ProgressiveClientOnly>
  );
}

export function TextareaFieldOL({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  let fallbackId = useId();
  let id = textareaProps.id ?? textareaProps.name ?? fallbackId;

  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );
  return (
    <ProgressiveClientOnly className="animate-fade">
      <div className={`${className + " grid grid-cols-3"} `}>
        <div className="col-start-1 col-span-1 justify-end justify-items-end text-end">
          <LabelOL
            htmlFor={id}
            {...labelProps}
            is_required={textareaProps.required}
          />
        </div>
        <div className="col-start-2 col-span-1 justify-start justify-items-start text-start">
          <Textarea
            id={id}
            aria-invalid={errorId ? true : undefined}
            aria-describedby={errorId}
            isRequiredError={isRequiredError}
            {...textareaProps}
          />
        </div>
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
      </div>
    </ProgressiveClientOnly>
  );
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
  description,
}: {
  labelProps: JSX.IntrinsicElements["label"];
  buttonProps: CheckboxProps & {
    name: string;
    form: string;
    value?: string;
  };
  errors?: ListOfErrors;
  className?: string;
  description?: string;
}) {
  const { defaultChecked, ...checkboxProps } = buttonProps;
  const fallbackId = useId();
  const checkedValue = buttonProps.value ?? "on";
  const input = useInputControl({
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  });
  const id = buttonProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  return (
    <ProgressiveClientOnly className="animate-fade">
      <div className={className}>
        <div className="flex gap-2">
          <Checkbox
            {...checkboxProps}
            id={id}
            aria-invalid={errorId ? true : undefined}
            aria-describedby={errorId}
            defaultChecked={input.value === checkedValue}
          />
          <label
            htmlFor={id}
            {...labelProps}
            className="self-center text-body-xs text-muted-foreground text-black dark:text-white"
          />
          {description && (
            <div className="group relative">
              <InformationCircleIcon className="text-black dark:text-white h-6 z-10 cursor-help" />
              <div
                className={`absolute left-1/2 -translate-x-1/2 bottom-full mb-2
                opacity-0 group-hover:opacity-100 transition-opacity duration-200
                bg-bg-light dark:bg-bg-dark text-gray-light dark:text-gray-dark 
                text-sm rounded px-4 py-2 w-auto z-50 shadow-lg`}
                role="tooltip"
                aria-hidden="true"
              >
                {description}
              </div>
            </div>
          )}
        </div>
        <div className="px-4 pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      </div>
    </ProgressiveClientOnly>
  );
}
