import * as React from "react";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isRequiredError: boolean;
}

let Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isRequiredError, ...props }, ref) => {
    return (
      <textarea
        className={`flex min-h-[80px] ${
          isRequiredError
            ? "ring-2 shadow-lg shadow-red-600/50 ring-red-600 dark:ring-red-600"
            : "ring-1 ring-button-light dark:ring-button-dark "
        } border-0 ring-inset w-full
          focus:ring-link-light focus:dark:ring-link-dark focus:ring-2 focus:ring-inset
          ring-button-light dark:ring-button-dark  
          text-text-light dark:text-text-dark 
          bg-bg-light dark:bg-bg-dark 
          rounded-md border-input px-3 py-2 text-sm placeholder:text-muted-foreground 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 
          disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid
          placeholder:text-light-placeholder dark:placeholder:text-dark-placeholder
          read-only:text-gray-400 read-only:ring-gray-500
          dark:read-only:text-gray-400 dark:read-only:ring-gray-500`}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
